import React from 'react'
import { graphql } from 'gatsby'
import ComponentsManager from '../utils/components-manager'
import Layout from '../layout/layout'
import SEO from "./seo";


/**
 * Landing page content type template
 * @param props
 * @returns {*}
 * @constructor
 */
const Event = (props) => {
    let node = props.data.nodeEvent;
    const description = (node.field_description && node.field_description.summary) ? node.field_description.summary : 'Find out about upcoming events and webinars where you can connect with Common App and hear from industry experts on college access, admissions and student success.';

    return(
        <Layout invertHeader={true}>
            <SEO title={node.title} article={true} description={description} />
            <div>
                <ComponentsManager node={node}
                                   breadcrumbCategory={props.pageContext.breadcrumbCategory}
                                   components={node.relationships.field_components} />
            </div>
        </Layout>
    )
};
export default Event

/**
 * Landing page query direct node
 *
 * @NOTE Don't forget to wire component fragments!
 */
export const EventQuery = graphql`
query EventQuery($drupalNodeId: Int!) {
  nodeEvent(drupal_internal__nid: {eq: $drupalNodeId})
  {
    title
    created
    
    field_end_date
    field_start_date
    field_location
    field_location_short
    field_registration_link {
      uri
      title
    }
    field_description {
      processed
      summary
    }
    
    relationships {

      field_event_type {
        name
      }

      field_event_image {
        localFile {
          childImageSharp {
              fluid(maxWidth: 828) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    
      field_components {
        __typename
        
        ...ParagraphsFixedContent
        ...ParagraphsSingleCardGradientBorderB
        ...ParagraphsSingleCardGradientBorder
        ...ParagraphsBluePhotoBanner
        ...ParagraphNewsletterSignup
        ...ParagraphTextCtaImage
        ...ParagraphsTwoCardsGradientBorder
        ...ParagraphsQuoteStatement
        
        ...ParagraphsVideoAndText
        ...ParagraphsBlogPostsCards
        ...ParagraphsEventListing
        
      }
    }
  }
}`;
